var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-none d-lg-block"
  }, [_c('li', {
    staticClass: "nav-item"
  }, [_c('span', [_c('b-button', {
    attrs: {
      "variant": "primary ml-1"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'login'
        });
      }
    }
  }, [_vm._v("Log In")])], 1), _c('span', [_c('b-button', {
    attrs: {
      "variant": "flat-primary ml-1"
    },
    on: {
      "click": _vm.handleSignupButtonClick
    }
  }, [_vm._v(" Sign Up ")])], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }