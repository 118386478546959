var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "navbar-container d-flex content align-items-center"
  }, [_c('ul', {
    staticClass: "nav navbar-nav d-lg-none"
  }, [_c('li', {
    staticClass: "nav-item"
  }, [_c('b-link', {
    staticClass: "navbar-brand",
    attrs: {
      "to": "/"
    }
  }, [_c('span', {
    staticClass: "brand-logo"
  }, [_c('b-img', {
    attrs: {
      "src": _vm.appLogoImage,
      "alt": "logo"
    }
  })], 1), _c('h2', {
    staticClass: "brand-text mb-0"
  }, [_vm._v(" " + _vm._s(_vm.appName) + " ")])])], 1)]), _c('app-breadcrumb'), _c('b-navbar-nav', {
    staticClass: "nav align-items-center ml-auto"
  }, [_c('search-bar'), _vm.me && !_vm.meLoading ? _c('notification-dropdown') : _vm._e(), _c('user-dropdown'), !_vm.me && !_vm.meLoading ? _c('authentication-links') : _vm._e()], 1), _c('ul', {
    staticClass: "nav navbar-nav d-xl-none"
  }, [_c('li', {
    staticClass: "nav-item"
  }, [_c('b-link', {
    staticClass: "nav-link",
    on: {
      "click": _vm.toggleVerticalMenuActive
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "MenuIcon",
      "size": "21"
    }
  })], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }