<template>
  <div class="d-none d-lg-block">
    <li
      class="nav-item"
    >
      <span>
        <b-button
          variant="primary ml-1"
          @click="$router.push({name: 'login'})"
        >Log In</b-button>
      </span>
      <span>
        <b-button
          variant="flat-primary ml-1"
          @click="handleSignupButtonClick"
        >
          Sign Up
        </b-button>
      </span>
    </li>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import { email, required } from '@core/utils/validations/validations'
import router from '@/router'

export default {
  components: {
    BButton,
  },
  data() {
    return {
      password: '',
      userEmail: '',
      status: false,
      required,
      email,
      loginErrors: [],
      showErrorNotifications: false,

      isLoading: false,
    }
  },
  methods: {
    router() {
      return router
    },
    onClose() {},
    onEnter(event) {
      this.login(event)
    },
    async login(event) {
      event.preventDefault()

      this.loginErrors = []
      this.showErrorNotifications = false

      const formValid = await this.$refs.observer.validate()
      if (!formValid) {
        return
      }

      this.isLoading = true

      const response = await this.$store.dispatch('auth/login', {
        email: this.userEmail,
        password: this.password,
      })

      if (response.status && response.errors.length === 0) {
        if (this.$route.query.redirect) {
          this.$router.push(this.$route.query.redirect)
        } else {
          this.$router.go(0)
        }
      } else {
        this.loginErrors = response.errors
      }

      this.showErrorNotifications = this.loginErrors.length > 0

      this.isLoading = false
    },
    handleSignupButtonClick() {
      this.$router.push({ name: 'auth-register' })
    },
  },
}
</script>
